import { grommet } from "grommet"
import { deepMerge } from "grommet/utils"

export default deepMerge(grommet, {
  global: {
    breakpoints: {
      small: {
        value: 768,
      },
      medium: {
        value: 1536,
      },
    },
    colors: {
      brand: "#000",
      brandpink: "#E9418F",
      brandpurple: "#7C68AD",
      brandblue: "#00D4D3",
      brandyellow: "#FFE500",
      focus: "#ff0043",
      grey: "#edebeb",
    },
    font: {
      family: "'Montserrat', sans-serif;",
      size: "12px",
    },
    heading: {
      extend: "letter-spacing: 0.01em",
      level: {
        1: {
          weight: 400,
          small: {
            size: "35px",
            height: "45px",
          },
          medium: {
            size: "52px",
            height: "62px",
          },
        },
        2: {
          weight: 400,
          small: {
            size: "30px",
            height: "40px",
          },
          medium: {
            size: "52px",
            height: "62px",
          },
        },
        3: {
          weight: 400,
          small: {
            size: "18px",
            height: "28px",
          },
          medium: {
            size: "24px",
            height: "34px",
          },
        },
        4: {
          weight: 400,
          small: {
            size: "16px",
            height: "24px",
          },
          medium: {
            size: "22px",
            height: "27px",
          },
        },
        5: {
          weight: 400,
          small: {
            size: "16px",
            height: "22px",
          },
          medium: {
            size: "20px",
            height: "24px",
          },
        },
        6: {
          weight: 600,
          margin: "0px",
          small: {
            size: "12px",
            height: "22px",
            extend: "letter-spacing: 2.5px",
          },
        },
      },
    },
  },
})
